import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react'

import { Footer } from 'components/Footer/Footer'
import { NewJerseyBanner } from 'components/NewJerseyBanner/NewJerseyBanner'
import { NewJerseyHeader } from 'components/NewJerseyHeader/NewJerseyHeader'
import { TestSiteBanner } from 'components/TestSiteBanner/TestSiteBanner'
import style from '../../../styles/components/layout.module.scss'

export const DefaultLayout = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('common')
  const showTestBanner = process.env.NEXT_PUBLIC_APP_ENV !== 'production'

  return (
    <>
      {/* Do not wrap in a landmark. See https://github.com/newjersey/dol-ui-claimant-intake/issues/6729 */}
      <a className="usa-skipnav" href="#main-content">
        {t('skip_nav')}
      </a>

      <div className={style.layout}>
        {showTestBanner && <TestSiteBanner />}
        <NewJerseyBanner />
        <NewJerseyHeader />
        <div className={style.main}>{children}</div>
        <div className={style.footer}>
          <Footer />
        </div>
      </div>
    </>
  )
}
